#Footer {
    background: #012840;
    background: linear-gradient(to bottom, rgba(1, 40, 65, 0.815), rgb(1, 41, 65)),
        url('../../img/Strategies.jpg');
    background-size: cover;
    background-attachment: fixed;
    background-position: center center;

    .subTitle,
    .mainTitle,
    .description {
        color: #fff;
    }

    .lineBtn {
        background: transparent;
        color: #45c2d1;

        &:hover{
            border-color: transparent ;
            &::before {
                background: #fff;
            }
        }
    }


    .subTitle {
        p {
            justify-content: center;
        }
    }

    ul {
        li {
            a {
                color: #cdcdcd;
            }
        }
    }
}