#Banner {
    background-position: center;
    min-height: 70vh;



    .mainServices {

        .serviceTitle,
        .img {

            padding: 1rem;
            transition: all .5s ease-in-out;

            // Animation
            &::after {
                border-radius: 50%;
                content: '';
                inset: 0;
                position: absolute;
                width: 100%;
                height: 100%;
                border: 4px dashed #45c2d1;
                -webkit-animation: spin 42s linear infinite;
                animation: spin 42s linear infinite;
                transform-origin: center;
            }


        }

        .serviceTitle {
            position: relative;
            padding: 2rem 0.5rem;
            z-index: 9;

            &::after {
                transition: all 0.5s ease-in-out;
                border-radius: 1rem;
                position: absolute;
                -webkit-animation: trans 42s linear infinite;
                animation: trans 42s linear infinite;
                background: #45c2d1;
                z-index: -1;
            }
        }

        &:hover {

            .serviceTitle,
            .img {
                opacity: 1;
                cursor: pointer;
                color: #45c2d1;

                &::after {
                    -webkit-animation: none;
                    animation: none;
                    border: 4px dashed #fff;
                    -webkit-animation: -spin 42s linear infinite;
                    animation: -spin 42s linear infinite;

                }
            }

            .serviceTitle {
                position: relative;
                z-index: 2;

                &::after {
                    -webkit-animation: trans 42s linear infinite;
                    animation: trans 42s linear infinite;
                    background: #fff;
                    transition: all 0.5s ease-in-out;
                    z-index: -1;
                    border: 4px dashed #fff;

                }

            }
        }
    }

}

.Logo {
    &:hover {
        .circle {
            border-color: #FFB700;
        }
    }

    .circle {
        height: 20rem;
        width: 40rem;
        left: calc(50% - 20rem);
        top: calc(50% - 10rem);
        filter: opacity(0.4);
        border: 2px dashed #fff;
        border-radius: 60%;
        animation: spin 30s linear infinite;
        transform-origin: center;

        &::after,
        &::before {
            content: "";
            position: absolute;
            width: 40rem;
            height: 20rem;
            border: 2px dashed #fff;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(1turn);
            z-index: -1;
            border-radius: 60%;
            animation: spinLogo 50s linear infinite;
            transform-origin: center;
        }

        &::before {
            animation-duration: 100s;
        }
    }

}

.rotateAnimation {
    span {
        border: 2px solid #FFB700;

        &:nth-child(3),
        &:first-child {
            width: 100%;
            height: 2px;
        }

        &:nth-child(2),
        &:nth-child(4) {
            height: 30%;
            width: 2px;
        }

        &:first-child {
            animation: rotateAnimation 8s infinite;
        }
    }
}

@media screen and (max-width : 768px) {
    .iconHeader div[aria-label=animation] {
        padding: 0 3rem !important;
        height: 20rem !important;
        margin: 2rem auto !important;

        * {
            overflow: visible !important;
        }

        svg {
            width: 100% !important;
            overflow: overlay;

        }
    }

    #Banner {
        max-height: initial;
        min-height: 50rem;

        .curve {
            bottom: -1rem;
        }

        .aboutBrainClick {
            width: 98%;
        }

        .rowServices {
            max-height: initial;
            min-height: auto;
        }

        .Logo {
            .circle {
                display: none;
            }

            img {
                width: 16rem;
                margin: 3rem auto;
            }
        }

        .container {
            padding: 2rem 0 4rem;
        }
    }


}

@media screen and (min-width: 768px) and (max-width: 1025px) {
    #Banner {
        max-height: fit-content;
        min-height: 60rem;

        .rowServices {
            min-height: 60rem;
            max-height: fit-content;
        }
    }
}

@media screen and (min-width: 1280px) and (max-width: 1440px) {
    .container {
        max-width: 1100px;
    }
}

@keyframes spinLogo {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
        opacity: 1;
    }

    to {
        transform: translate(-50%, -50%) rotate(1turn);
        opacity: 1;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg)
    }

    to {
        transform: rotate(1turn)
    }
}

@keyframes -spin {
    0% {
        transform: rotate(0deg)
    }

    to {
        transform: rotate(-1turn)
    }
}


@keyframes rotateAnimation {
    0% {
        left: -100%;
    }

    to {
        left: 100%;
    }
}

@keyframes trans {
    0% {
        transform: translateY(0)
    }

    to {
        transform: translateY(180deg)
    }
}

div[aria-label="animation"] {
    height: 30rem !important;
}