 #Statistics {
           background: #012840;
           background: linear-gradient(to right, rgba(1, 41, 65, 0.466), rgb(1, 41, 65)),
                     url('../../img/Statistics.jpg');
           background-size: cover;
           background-attachment: fixed;
           background-position: center center;

           .item {
                     border: 0;
           }
 }