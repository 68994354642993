nav {
  background-color: #012840;
  transition: all 1s ease-in-out;
}

.navServices {
  // background: #012840 !important;
  transition: all 1s ease-in-out;
  transform: translateY(-200%);
  z-index: -9999;

  a {
    border: 0.5px solid #01314e;
    background: #012840 !important;

    display: block;
    padding: 2rem 1rem;

    h3 {
      color: #45c2d1;
      letter-spacing: 1px;
    }
  }

  &.opend {
    transition: all 1s ease-in-out;
    // padding-top: 2rem;
    transform: translateY(0);
    z-index: 9;
  }
}



#HomePage {
  nav {
    background-color: transparent;

    .navServices {
      // background-color: #000 !important;

      a {
        background: #000;
        border: 0.5px solid #ffffff2b;
      }
    }

    &.active {
      background-color: #012840;

      .container {
        // background-color: #012840;
        transition: all 1s ease-in-out;
      }

      .navServices {
        // background-color: #012840 !important;

        a {
          background: transparent;
        }
      }
    }

  }
}

.navigation {
  width: 100%;
  height: 60px;
  background: transparent;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  // border-radius: 1rem 1rem 0 0;
  bottom: 0;
  margin: 0 auto;
  left: 0;
  z-index: 999999;
  // border-top: 1rem solid #fff;

  ul {
    display: flex;
    width: 350px;

    li {
      position: relative;
      list-style: none;
      width: 70px;
      height: 70px;
      z-index: 1;

      a {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        text-align: center;
        font-weight: 600;

        .icon {
          position: relative;
          display: block;
          line-height: 75px;
          font-size: 1.5em;
          text-align: center;
          color: #fff;
          transition: 0.5s;
          margin-top: 2rem;

        }
      }

      &.active {
        a .icon {
          transform: translateY(-32px);
          margin-top: 3rem;
        }

        .icon {
          color: #012840;
        }
      }


      a .text {
        position: absolute;
        color: #333;
        font-weight: 600;
        font-size: .75em;
        letter-spacing: 0.05em;
        transition: 0.5s;
        opacity: 0;
        transform: translateY(20px);
      }

      &.active a .text {
        opacity: 1;
        transform: translateY(10px);
      }
    }
  }
}

.indicator {
  position: absolute;
  top: -68%;
  width: 70px;
  height: 70px;
  background: #41c2d1;
  border-radius: 50%;
  border: 6px solid #fff;
  transition: 0.5s;
  display: none;

  &::before {
    content: '';
    position: absolute;
    top: 49%;
    left: -21px;
    height: 20px;
    width: 20px;
    background: transparent;
    border-top-right-radius: 20px;
    box-shadow: 0 -10px 0 0 #fff;
  }

  &::after {
    content: '';
    position: absolute;
    top: 49%;
    right: -21px;
    height: 20px;
    width: 20px;
    background: transparent;
    border-top-left-radius: 20px;
    box-shadow: 0 -10px 0 0 #fff;
  }
}

.navigation ul li {
  &:nth-child(1).active~.indicator {
    transform: translateX(calc(70px * 0));
    display: block;
  }

  &:nth-child(2).active~.indicator {
    transform: translateX(calc(70px * 1));
    display: block;
  }

  &:nth-child(3).active~.indicator {
    transform: translateX(calc(70px * 2));
    display: block;
  }

  &:nth-child(4).active~.indicator {
    transform: translateX(calc(70px * 3));
    display: block;
  }

  &:nth-child(5).active~.indicator {
    transform: translateX(calc(70px * 4));
    display: block;
  }
}

.topNav {
  background: #012840;
  box-shadow: -1px 5px 18px #000000ab;
}

nav {
  transition: all 0.5s ease-in-out;

  &.active {
    position: fixed;
    background: #012840;
    box-shadow: -1px 5px 18px #000000ab;
    zoom: 0.9;

    .Logo {
      transform: scale(0.8);
    }
  }
}

body[dir="rtl"] {
  .langButton {
    margin-left: 0;
    margin-right: auto;
  }

  .arrowButton {
    transform: scaleX(-1);
  }

}


.glow-on-hover {
  color: #111;
  // background: #111;
  cursor: pointer;
  position: relative;
  z-index: 990;
  border-radius: 1rem;
}

.glow-on-hover:before {
  content: '';
  background: linear-gradient(45deg, #04c0c0, #00fff2, #058eb1, #00a2ff, #00a2ff, #00ffff, #00b7ff, #0099ff, #05969b);
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite;
  opacity: 0;
  transition: opacity .3s ease-in-out;
  border-radius: 10px;
}



.glow-on-hover:active:after {
  background: transparent;
}

.glow-on-hover:before {
  opacity: 1;
}

.glow-on-hover:after {
  z-index: -1;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 10px;
  background: #04c0c091;
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 400% 0;
  }

  100% {
    background-position: 0 0;
  }
}

.modal {
  z-index: 999999;
  min-width: 40rem;
}

.modalBack {
  z-index: 99999;
}

.modal {
  div[aria-label=animation] {
    height: auto !important;
  }
}