 .imageItem {
           height: 80rem;

           img {
                     border-radius: 0.5rem;
           }

           &.sub {
                     height: 40rem;
           }
 }

 .aboutData {
           .w-full {
                     border-color: #ccc !important;

                     .description {
                               margin-bottom: 1rem;
                     }

                     &:first-child {
                               border-top: 1px dashed;
                               border-left: 1px dashed;
                     }

                     &:nth-child(2) {
                               border: 1px dashed;
                               border-top: 0;
                     }

                     &:last-child {
                               border-top: 1px dashed;
                               border-right: 1px dashed;

                     }
           }
 }

 @media screen and (max-width : 768px) {
          .mainImage{
                    height: 40rem;
          }
 .aboutData {
          .w-full {
                    border-color: #ccc !important; 

                    &:first-child {
                              border-top: 1px dashed;
                              border-left: 1px dashed;
                    }

                    &:nth-child(2) {
                              border: 0;
                              border-top: 1px dashed;
                              border-right: 1px dashed;
                    }

                    &:last-child {
                              border-top: 1px dashed;
                              border-right: 0;
                              border-left: 1px dashed;
                    }
          }
} 
 }