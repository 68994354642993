 

.card{
          border-radius: 0.5rem;
          background: gray; 
          .subTitle{
                    p{
                              &::after{
                                        content: '';
                                        position: absolute;
                                        bottom: 0;
                                        left: 0;
                                        width: 5rem;
                                        height: 2px;
                                        background: #45c2d1;
                              }
                    }
          }
}