 

#Strategies{
          background: #19222d;
          background: linear-gradient(to right, rgba(1, 40, 65, 0.76)  , rgb(1, 41, 65)),
          url('../../img/Strategies.jpg');
          background-size: cover;
          background-attachment: fixed;
          background-position: center center;
          .StrategyCard{
                    .card:nth-child(2){
                              margin-top: 8rem;
                              background: #fff;
                              *:not(.btn){
                                        color: #19222d;
                              } 
                    }
          }
          .subTitle, .mainTitle, .description {
                    color: #fff;
                }
}