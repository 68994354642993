 .portfolio {
           div[aria-label=animation] {
                     height: auto !important;
           }
 }

 .Tabs.portfolio {
           .subTitle span {
                     background: #45c2d1;
                     margin: 0 auto;
           }
 }