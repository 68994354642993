.imgCard {
          height: 38rem;
          cursor: url(../../img/99967-heart.gif), auto !important;
 

          img,
          .detailsCard {
                    transition: all 1s ease-in-out;
          }

          &:hover {
                    transition: all 1s ease-in-out;

                    img {
                              filter: contrast(1.1);
                    }

                    .detailsCard {
                              bottom: 0;
                    }
          }
}
 
@media screen and (max-width : 767px) {
          .imgCard{
                    height: 21rem;
          }
          .manger{
                    height: 40rem;
          }
}