@import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@200;300;400;500;700;800;900&display=swap');

*,
::after,
::before {
    box-sizing: border-box;
    outline: none;
}

::-webkit-scrollbar {
    width: 5px
}

::-webkit-scrollbar-thumb {
    background: #777
}

::-webkit-scrollbar-thumb:hover {
    background: #555
}

html {
    font-size: 62.5%;
    scroll-behavior: smooth;

}

html,
body {
    padding: 0;
    margin: 0;
    overflow-x: hidden;
    scroll-behavior: smooth;
}


body {
    font-size: 1.6rem;
    // background-color: #fff;
    font-family: 'Tajawal', sans-serif;
}

a {
    text-decoration: none;
    cursor: pointer;
    outline: none;
}

.text-overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}

.lines {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    white-space: inherit;
    -webkit-box-orient: vertical;

    &.three {
        -webkit-line-clamp: 3;
    }

    &.five {
        -webkit-line-clamp: 5;
    }
}

.Error {
    background-color: #012840;
}

// #otherPage {
//           background: #012840;
// }

.otherPage {
    nav {
        background: #012840;
        transition: all 1s ease-in-out;
        letter-spacing: 2;
    }
}

.prgraph-Column {
    column-count: 3;
    column-rule: 1px solid #fff;
    column-gap: 4rem;
    hyphens: auto;
}

html[lang="en"] {
    .LogoAR {
        display: none;
    }

    .logoEn {
        display: block;
    }
}

html[lang="ar"] {
    * {
        letter-spacing: 0;
    }

    .logoEn {
        display: none;
    }

    .LogoAR {
        display: block;
    }

    ul.listItem {
        direction: rtl;
    }

    div#Idea {
        .w-full.lg\:w-1\/2.md\:py-4.lg\:pr-32.mb-8.md\:mb-0 {
            padding-right: 2rem;
            padding-left: 6rem;
        }
    }

    #Strategies {
        .text-white.w-full.lg\:w-1\/2.md\:py-4.md\:px-32.md\:pl-0.mb-8.md\:mb-0 {
            padding-right: 2rem;
            padding-left: 6rem;
        }
    }

    #FAQ {
        .w-full.pr-0.sm\:pr-8.xl\:w-8\/12 {
            padding-right: 0;
            padding-left: 2rem;
        }
    }


    .subTitle span {
        margin-right: 0;
        margin-left: 1rem;
    }

    .card .subTitle p::after {
        left: auto;
        right: 0;
    }

    .planCard {
        text-align: right;

        ul.listItem {
            li {
                direction: rtl;

                span.bg-cyan-500 {
                    margin-right: 0;
                    margin-left: 1rem;
                }

                span.w-10\/12 {
                    text-align: right;
                }

                svg.absolute.right-4.text-cyan-500 {
                    left: 1rem;
                    right: auto;
                }
            }

        }
    }

    #aboutSection {
        .w-full.md\:w-1\/2.md\:py-4.mb-8.md\:mb-0.pr-4 {
            padding-right: 0;
            padding-left: 1rem;
        }
    }



    svg.mr-4.text-cyan-500 {
        margin-left: 1rem;
        margin-right: 0;
    }

    a.text-white.flex.items-center.text-3xl.mr-4 {
        direction: ltr;
    }

    #Banner {
        div div a {
            .mr-4 {
                margin-right: 0;
                margin-left: 1rem;
            }
        }
    }

    button.text-white.mr-0.ml-auto.langButton.flex.flex-col.items-center {
        margin-left: 0;
        margin-right: auto;
    }

    .navigation ul li {
        &:nth-child(1).active~.indicator {
            transform: translateX(calc(70px * -0));
        }

        &:nth-child(2).active~.indicator {
            transform: translateX(calc(70px * -1));
        }

        &:nth-child(3).active~.indicator {
            transform: translateX(calc(70px * -2));
        }

        &:nth-child(4).active~.indicator {
            transform: translateX(calc(70px * -3));
        }

        &:nth-child(5).active~.indicator {
            transform: translateX(calc(70px * -4));
        }
    }
}


.slick-next:before {
    content: "→" !important;
}

.slick-prev::before {
    content: "←" !important;
}

button.slick-arrow.slick-prev,
button.slick-arrow.slick-next {
    left: 50%;
    position: absolute;
    top: auto;
    bottom: 3rem;
    width: 4rem;
    height: 4rem;
}

.slick-prev:before,
.slick-next:before {
    font-size: 3rem;
    opacity: 1;
}

.closeBtn {

    &:hover {
        &.btn::before {

            background: rgb(180, 4, 4);
        }

        * {
            stroke: #fff;
        }
    }
}

ul.listItem {
    height: 26rem;
    position: relative;
    overflow: auto;
}

ul.listItem::-webkit-scrollbar {
    width: 8px
}

ul.listItem::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #45c2d1;
    border-radius: 10px
}

ul.listItem::-webkit-scrollbar-thumb {
    background: #45c2d1;
    border-radius: 10px
}

ul.listItem::-webkit-scrollbar-thumb:hover {
    background: #24737b
}

.planCard {
    transform: scale(0.95);
    transition: all 1s ease-in-out;

    &:hover {
        transform: scale(1);
    }
}

.sideBar {
    background-color: #012840;
    left: -100%;
    transition: all .5s ease-in-out;

    &.active {
        left: 0;
    }
}

.whatsappBtn {
    position: fixed;
    left: 1rem;
    right: auto;
    bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
}

html[dir="rtl"] {
    #Footer .w-full.sm\:w-1\/2.lg\:w-1\/4.py-12.pr-4 {
        text-align: right;
    }

    #Footer h3 {
        text-align: right;
    }

    .whatsappBtn {
        left: auto;
        right: 1rem;
    }

    .sideBar.active {
        right: 0;
        left: auto;
    }

    .sideBar {
        left: auto;
        right: -100%;

    }
}

@media screen and (max-width: 768px) {

    #aboutSection {
        .lines.five {
            -webkit-line-clamp: inherit;
        }
    }

    #FAQ {
        .w-full.pr-0.sm\:pr-8.xl\:w-8\/12 {
            padding-left: 0 !important;
        }
    }

    .prgraph-Column {
        column-count: 1;
        column-gap: 1rem;
    }

    .navServices a {
        padding: 1rem;
    }

    html[dir=rtl] {
        #Footer .w-full.sm\:w-1\/2.lg\:w-1\/4.py-12.pr-4 {
            text-align: center !important;
        }

        #FAQ {
            button.px-8.py-8.flex.items-start.justify-between.w-full {
                padding-right: 0.5rem;
                text-align: right;
            }

            h1.text-left.font-semibold.blueColor {
                text-align: right !important;
            }
        }

        #Idea {
            .w-full.lg\:w-1\/2.md\:py-4.lg\:pr-32.mb-8.md\:mb-0 {
                padding-right: 0 !important;
                padding-left: 0 !important;
            }
        }
    }

    #Footer {
        h3 {
            text-align: center !important;
        }

        ul li a {
            display: flex;
            justify-content: center;
            text-align: center;

        }

    }
}