 

.slick-slider {
          padding-bottom: 9rem;
}

// button.slick-arrow.slick-prev,
// button.slick-arrow.slick-next {
//           // display: none !important;
//           left: 50%;
//           right: auto;
//           position: absolute;
//           top: auto;
//           bottom: 0rem;
//           width: 4rem;
//           height: 4rem;
//           border: 2px solid #012840;
//           border-radius: 50%;
//           font-weight: 900;
// }

.slick-prev:before,
.slick-next:before {
          font-weight: 900;
          color: #012840 !important;

}
 
.slick-prev {
          left: calc(50% - 5rem) !important;

          
}

.planCard {
          border: 2px solid #45c2d1;
          border-radius: 0.5rem;

          .subTitle {
                    line-height: 1.3;
          }
}