.subTitle,
.mainTitle,
.description {
    color: #19222d;
}

.btn {
    background: #41c2d1;
    padding: 1rem 2rem;
    font-size: 1.8rem;
    color: #19222d;
    letter-spacing: 0.1rem;
    border-radius: 0.5rem;
    transition: all 0.5s ease;
    position: relative;
    overflow: hidden;
    z-index: 2;
    font-weight: 600;

    &::before {
        width: 0;
        height: 0;
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        background: linear-gradient(45deg, #052a41 10% 20%, #19222d 50% 100%);
        z-index: -1;
        border-radius: 50%;
        transform: translate(-50%, -50%);
        transition: all 0.5s ease;

    }

    &:hover {
        color: #fff;
        box-shadow: none;

        &::before {
            width: 102%;
            height: 101%;
            border-radius: 0rem;
        }
    }

    &.lineBtn {
        background: #fff;
        border: 2px solid #45c2d1;
        box-shadow: none;
        text-align: center;

        &:hover {
            border: 2px solid #19222d;
            background: linear-gradient(45deg, #052a41 10% 20%, #19222d 50% 100%);

        }
    }
}


.mainTitle {
    font-weight: 900;
    font-size: 4rem;
    text-transform: capitalize;
}

.description {
    p {
        line-height: 2.2;
        font-size: 1.6rem;
        margin: 1rem auto;
    }
}

.subTitle {
    span {
        background: #45c2d1;
    }
}

.link {
    transition: all 0.5s ease-in-out;

    &:hover {
        color: #45c2d1;
    }
}


.yellowColor {
    color: #45c2d1;
}

.blueColor {
    color: #19222d;
}

.grayColor {
    color: #888;
}