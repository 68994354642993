body[dir="rtl"] {
    #Banner{
        .mr-4 {
            margin-right: 0;
            margin-left: 1rem;
        }
    }
    nav {
        a.text-white.flex.items-center.text-3xl.mr-4 {
            direction: ltr;
        }
    }

    .text-left {
        text-align: right;
    }
 
    .navigation ul li:nth-child(5).active~.indicator {
        transform: translateX(-280px);
    }

    .navigation ul li:nth-child(4).active~.indicator {
        transform: translateX(-210px);
    }

    .navigation ul li:nth-child(3).active~.indicator {
        transform: translateX(-140px);
    }

    .navigation ul li:nth-child(2).active~.indicator {
        transform: translateX(-70px);
    }

    .navigation ul li:nth-child(1).active~.indicator {
        transform: translateX(0px);
    }

    .langButton {
        margin-left: 0;
        margin-right: auto;
    }

    .subTitle {
        p {
            flex-direction: row-reverse;
            justify-content: start;
        }
    }

    .card .subTitle p::after {
        left: auto;
        right: 0;
    }

    .planCard {
        text-align: right;

        .price {
            flex-direction: row-reverse;

            .border-r-4 {
                border-right-width: 0;
                border-left-width: 4px;
            }

            .mr-6 {
                margin-right: 0;
                margin-left: 1.5rem;
                padding-right: 0;
                padding-left: 1.5rem;
            }
        }
    }

    .listItem {
        li {
            flex-direction: row-reverse;

            .mr-4 {
                margin-right: 0;
                margin-left: 1rem;
            }

            .right-4 {
                right: auto;
                left: 1rem;
            }
        }
    }

    #aboutSection {
        .pr-4 {
            padding-right: 0;
            padding-left: 1rem;
        }
    }

    #Footer {
        .w-full.sm\:w-1\/2.lg\:w-1\/4.py-12.pr-4 {
            text-align: right;
        }

        .mr-4 {
            margin-right: 0;
            margin-left: 1rem;
        }
        span.relative.flex.mr-4.overflow-hidden.h-1.w-40{
            margin-right: 1rem;
        }
    }
    .checkBox {
        padding-left: 0;
        padding-right: 4rem;
        .checkmark {
            margin-right: 0;
            margin-left: 6rem;
            right: 0;
            left: auto;
        }
    }
    #ourPackage {
        button.btn{
            margin-left: 0;
            margin-right: auto;
        }
        .md\:pl-0 {
            padding-left: 0px;
            padding-right: 0;
        }
        
        
    }
    section#Contact{
        .md\:px-32 {
            padding-left: 1rem;
            padding-right: 1rem;
        }
        .mr-4 {
            margin-left: 1rem;
            margin-right: 0;
        }
    }
}
.ltrDir {
    direction: ltr;
}
@media screen and (max-width: 768px) {
    body[dir="rtl"] {
        #Footer .w-full.sm\:w-1\/2.lg\:w-1\/4.py-12.pr-4 {
            text-align: center;
        }
    }
}