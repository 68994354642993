 .leftSection {

           &::after,
           &::before {
                     position: absolute;
                     top: -50%;
                     left: calc(50% - 1px);
                     width: 1px;
                     height: 10rem;
                     background: #45c2d1;
                     content: "";
           }

           &::after {
                     top: auto;
           }

           a {
                     color: #45c2d1;
                     transition: 0.5s ease-in-out;

                     &:hover {
                               transform: rotate(0); 
                     }
           }
 }

 .rightSection {
           .btn {
                     line-height: 1.2;
                     padding: 1rem 1rem;

                     &.messenger {
                               font-size: 2.6rem;
                               padding: 0.6rem;

                     }
           }

 }