html[dir='rtl'] {
          .LogoAR {
                    display: block;
          }

          .logoEn {
                    display: none;
          }

          img.w-12.mr-4.ar {
                    display: none;
          }
}

html[dir='ltr'] {
          .LogoAR {
                    display: none;
          }

          .logoEn {
                    display: block;
          }
}

// 
// logoEn.

.video-responsive {
          overflow: hidden;
          padding-bottom: 56.25%;
          position: relative;
          height: 0;
}

.video-responsive iframe {
          left: 0;
          top: 0;
          height: 100%;
          width: 100%;
          position: absolute;
}