

textarea,
input{
          border: 1px solid rgb(223, 223, 223);
          border-radius: 0.5rem
}

.header{
          background: linear-gradient(74deg, #45c2d1 37% 20%, #01c7df 50% 100%);
          border-radius: 0.5rem ; 
 
}
